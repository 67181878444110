<template>
  <div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">销售工单列表</div>
        <div class="contentTopRight">
          <el-upload action="" :auto-upload="false" :multiple="false" :show-file-list="false" :on-change="uploadByJsqd"
            :file-list="fileList">
            <el-tooltip class="item" effect="dark" content="导入" placement="top">
              <img src="@/assets/images/production/leading-in.png" alt="">
            </el-tooltip>
          </el-upload>
          <el-tooltip class="item" effect="dark" content="刷新" placement="top">
            <img src="@/assets/images/production/renovate.png" alt="" @click="renovate">
          </el-tooltip>
          <el-input placeholder="" suffix-icon="el-icon-search" size="small" v-model="input2" @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="showId" label="序号" fixed="left" width="80">
        </el-table-column>
        <el-table-column prop="orderStatus" label="状态" width="120">
          <template slot="header" slot-scope={}>
            <el-dropdown trigger="click" size="medium" class="OrderStatus" @command="handleCommand">
              <span style="color:#909399">
                状态<i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in section" :key="index" :command="item">
                  {{ item }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            <span
              :class="{ 'cell_Green': (scope.row.orderStatus === 1), 'cell_Yellow': (scope.row.orderStatus === 2) }"></span>
            <span style="margin-left: 10px" v-if="(scope.row.orderStatus === 1)">已确认</span>
            <span style="margin-left: 10px" v-if="(scope.row.orderStatus === 2)">未确认</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderNo" label="工单号" width="190">
        </el-table-column>
        <el-table-column prop="priority" label="优先级" width="100">
        </el-table-column>
        <el-table-column prop="customerName" label="客户" width="110">
        </el-table-column>
        <el-table-column prop="productName" label="产品名称">
        </el-table-column>
        <el-table-column prop="partCode" label="图号">
        </el-table-column>
        <el-table-column prop="versionCode" label="版本号">
        </el-table-column>
        <el-table-column prop="plannedQuantity" label="生产数量">
        </el-table-column>
        <el-table-column prop="orderCompletionDateStr" label="需求日期">
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination> </span>
      </div>
    </div>
    <resultDialog :name="name" :fileName="fileName" :resultMsg="resultMsg" :resultVisible="resultVisible"
      @reUpload="reUpload" :type="type" @update:resultVisible="closeFn" />
  </div>
</template>

<script>
const resultDialog = () => import("@/components/upload/resultDialog.vue")
import { getSaleData, importSale, searchSaleData } from '@/api/orders.js'
export default {
  name: "production",
  components: {
    resultDialog
  },
  data() {
    return {
      name: '',
      fileName: '',
      resultVisible: false,
      resultMsg: '',
      type: true,
      status: false,
      tableData: [],
      total: 0, //总条数，用于分组组件
      currentPage1: 1,
      input2: '',
      tempData: [],
      tempData2: [],  //筛选后的数据
      section: [
        '已确认',
        '未确认'
      ],
      limitNum: 1, //文件上传个数限制
      fileList: [], //文件列表
      pageChange: 10,   //当前页面多少条订单
      tempTotal: 0, //总条数，但与分页组件无关
      searchStatus: false,
      searchData: []
    }
  },
  methods: {
    // 分页组件--一页显示多少条数据
    async handleSizeChange(val) {
      this.pageChange = val
      if (this.status) {
        this.tempData = this.tempData2.filter((v, i) => i < val)
      } else if (this.searchStatus) {
        this.tempData = this.searchData.filter((v, i) => i < val)
      } else {
        const res = await getSaleData(1, val)
        const { records } = res.data
        this.tableData = records
        this.tempData = this.tableData
      }
      this.currentPage1 = 1
    },
    // 分页组件--选择页数
    async handleCurrentChange(val) {
      if (this.status) {
        // 如果数据经过筛选
        this.tempData = this.tempData2.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else if (this.searchStatus) {
        this.tempData = this.searchData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else {
        const res = await getSaleData(val, this.pageChange)
        const { records } = res.data
        this.tableData = records
        this.tempData = records
      }
      this.currentPage1 = val
    },
    async searchHandler() {
      const res = await searchSaleData(1, this.tempTotal, this.input2)
      // 搜索后的总条数
      const { records } = res.data
      this.searchData = records
      this.searchStatus = true
      this.pageFn()
      this.tempData = this.searchData.filter((v, i) => i < 10)
      this.total = this.searchData.length
    },
    // 筛选状态
    async handleCommand(item) {
      const res = await getSaleData(1, this.tempTotal)
      const { records } = res.data
      this.status = true
      this.pageFn()
      if (item === '已确认') {
        this.tempData2 = records.filter(v => v.orderStatus === 1)
        this.tempData = this.tempData2.filter((v, i) => i < 10)
      } else if (item === '未确认') {
        this.tempData2 = records.filter(v => v.orderStatus === 2)
        this.tempData = this.tempData2.filter((v, i) => i < 10)
      }
      this.total = this.tempData2.length
    },
    async renovate() {
      // 刷新，重新赋值数据
      const res = await getSaleData(1, 10)
      const { records, total } = res.data
      this.tableData = records
      this.tempData = records
      this.total = total
      this.tempTotal = total
      this.pageFn()
      this.status = false
      this.searchStatus = false
      this.input2 = ''
    },
    pageFn() {
      this.pageChange = 10
      this.currentPage1 = 1
    },
    beforeAvatarUpload(file) {
      let fileArr = file.name.split('.')
      let suffix = fileArr[fileArr.length - 1]
      if (!/(xls|xlsx)/i.test(suffix)) {
        this.$message('文件格式不正确')
        return false
      }
      if (file.size > 10 * 1024 * 1024) {
        this.$message('文件过大，请上传小于10MB的文件〜')
        return false
      }
      return true
    },
    uploadByJsqd(file) {
      if (this.beforeAvatarUpload(file)) {
        this.fileList.name = file.name
        this.fileName = file.name
        this.fileList.url = ''
        let formdata = new FormData()
        formdata.append('file', file.raw)
        importSale(formdata).then((res) => {
          if (res.code === 0) {
            this.resultVisible = true
            this.renovate()
          } else if (res.data !== 0) {
            this.resultVisible = true
            this.type = false
            this.resultMsg = res.msg
          }
        }, (err) => {
          console.log('当前打印err错误:', err)
        })
      }
    },
    closeFn() {
      this.resultVisible = false
    },
    reUpload() {
      let temp = document.querySelectorAll('.el-upload')
      temp[0].click()
    }
  },
  async activated() {
    await this.renovate()
  }
}
</script>

<style lang="sass" scoped>
@import '../production/style/style.scss'
</style>