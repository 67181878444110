import {get, post } from "../utils/http"
import { baseUrls } from '../utils/urls'
const aps1 = baseUrls.aps1;

// 生产订单列表
export function getProductionData(page, size) {
    return get(`${aps1}/productionOrder/page?page=${page}&size=${size}`)
}

// 生产订单列表--搜索
export function searchProductionData(page, size, searchValue) {
    return get(`${aps1}/productionOrder/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 导入生产订单
export function importProduction(params) {
    return post(`${aps1}/excel/importProductionOrder`, params)
}

// 导入宜搭订单
export function importOrder(page, size) {
    return get(`${aps1}/productionOrder/importOrder?page=${page}&size=${size}`)
}

// 散件
export function updateById(params) {
    return post(`${aps1}/productionOrder/updateById`, params)
}

// 拆分
export function incorporateOrder(params) {
    return post(`${aps1}/productionOrder/incorporateOrder`, params)
}

// 合并
export function splitOrder(params) {
    return post(`${aps1}/productionOrder/splitOrder`, params)
}

// 销售订单列表
export function getSaleData(page, size) {
    return get(`${aps1}/salesOrder/page?page=${page}&size=${size}`)
}

// 销售订单列表--搜索
export function searchSaleData(page, size, searchValue) {
    return get(`${aps1}/salesOrder/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 导入销售订单
export function importSale(params) {
    return post(`${aps1}/excel/importSalesOrder`, params)
}